<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="form"
    class="workflow-step-modal_form"
    #ngForm="ngForm"
    (ngSubmit)="save()"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'customFormModal.title' | translate }}</span>
      </div>
      <div>
        <div>{{ 'customFormModal.titleMsg' | translate }}</div>
        <button
          type="submit"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div class="custom-form-modal_tabs">
      <div
        [appFocusable]
        [class.active]="selectedTab === tabs.build"
        (click)="selectedTab = tabs.build"
      >
        {{ 'customFormModal.tabs.build' | translate }}
      </div>
      <div
        [appFocusable]
        [class.active]="selectedTab === tabs.settings"
        (click)="selectedTab = tabs.settings"
      >
        {{ 'customFormModal.tabs.settings' | translate }}
      </div>
    </div>

    <div [ngStyle]="{ height: maxScrollAreaHeight }">
      <div
        [class.hidden]="selectedTab !== tabs.build"
        class="custom-form-modal_build"
      >
        <form-builder
          #formBuilder
          [(logicFields)]="logicFields"
          [workflowStepId]="workflowStepId"
          [isCompanyForm]="workflowType === 'Company'"
          [(formData)]="formData"
        ></form-builder>
      </div>

      <div
        [class.hidden]="selectedTab !== tabs.settings"
        class="custom-form-modal_settings"
      >
        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('name').invalid,
          }"
        >
          <label
            class="required"
            for="stepName"
            >{{ 'workflows.controls.stepName.label' | translate }}</label
          >
          <input
            formControlName="name"
            id="stepName"
            placeholder="{{ 'workflows.controls.stepName.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('name')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('description').invalid,
          }"
        >
          <label
            class="required"
            for="description"
            >{{ 'workflows.controls.stepDescription.label' | translate }}</label
          >
          <textarea
            formControlName="description"
            id="description"
            placeholder="{{ 'workflows.controls.stepDescription.placeholder' | translate }}"
          >
          </textarea>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('description')"
          >
          </form-error-messages>
        </div>
      </div>
    </div>
  </form>
</div>
