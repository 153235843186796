import { Component, Input, type OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  type DecisionCriteria,
  DecisionCriteriaOperator,
  type DecisionCriteriaState,
} from '../../models/decision-criteria';
import { FieldGroupType, type WorkflowField } from '../../models/workflow-field';
import { type SelectOption } from '@core/components/select/select.component';
import { DESICION_BRANCHES, MULTIPLE_CRITERIA_OPERATORS } from '../../constants/decision-criteria-select-options';
import { WorkflowsService } from '../../workflows.service';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { WorkflowType } from '../../models/workflow';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from '../../../core/components/select/select.component';
import { CriteriaSelectorComponent } from '../../components/criteria-selector/criteria-selector.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { ScrollableAreaComponent as ScrollableAreaComponent_1 } from '../../../core/components/scrollable-area/scrollable-area.component';
import { LoaderComponent } from '../../../core/components/loader/loader.component';
import { NgStyle, NgClass } from '@angular/common';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-decision-modal',
  templateUrl: './decision-modal.component.html',
  styleUrls: ['./decision-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgStyle,
    LoaderComponent,
    ScrollableAreaComponent_1,
    NgClass,
    FormErrorMessagesComponent,
    CriteriaSelectorComponent,
    SelectComponent,
    TranslateModule,
  ],
})
export class DecisionModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly workflowsService = inject(WorkflowsService);
  @Input() data: any;
  @Input() workflowType: WorkflowType;
  @ViewChild('scrollArea') scrollArea: ScrollableAreaComponent;

  public form: UntypedFormGroup;

  public criterias: DecisionCriteria[] = [];
  public criteriaOperator: string;
  public fields: WorkflowField[];

  public branchOptions: SelectOption[] = DESICION_BRANCHES;
  public operatorOptions: SelectOption[] = MULTIPLE_CRITERIA_OPERATORS;

  public maxScrollAreaHeight: string;

  criteriaStates: Record<string, DecisionCriteriaState[]> = {};

  private lastId = 0;

  private get areCriteriasInvalid(): boolean {
    return (
      this.criterias.find((criteria) => {
        if (criteria.fieldId == null || criteria.state == null) {
          return true;
        }

        const states = this.getStates(criteria);
        const selectedState = states.find((state) => state.key === criteria.state);

        if (criteria.targetFieldId == null && selectedState.isValueRequired && criteria.value == null) {
          return true;
        }

        return false;
      }) !== undefined
    );
  }

  public ngOnInit(): void {
    const data = this.data ? this.data : {};
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      branch: new UntypedFormControl(data.branch, [Validators.required]),
      operator: new UntypedFormControl(data.operator === undefined ? DecisionCriteriaOperator.Any : data.operator),
    });

    if (this.data?.criteria) {
      this.criterias = [];
      this.data.criteria.forEach((criteria) => this.criterias.push({ ...criteria }));

      this.criterias.forEach((crit) => {
        if (crit.id > this.lastId) {
          this.lastId = crit.id;
        }
      });
    } else {
      this.criterias.push({
        id: 0,
        fieldId: null,
        state: null,
        targetFieldId: null,
        value: '',
      });
    }

    this.workflowsService.getDesicionCriteriaStates().then((res) => {
      this.criteriaStates = res;
      this.loadFields();
    });
  }

  public ngAfterViewInit(): void {
    this.calculateModalHeight();
  }

  public getMaxScrollAreaHeight(): string {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return `${document.body.clientHeight - headerHeight - modalHeaderHeight - 40}px`;
  }

  public onCriteriaChange(val: DecisionCriteria): void {
    const criteria = this.criterias.find((crit) => crit.id === val.id);
    criteria.fieldId = val.fieldId;
    criteria.fieldGroup = val.fieldGroup;
    criteria.state = val.state;
    criteria.target = val.target;
    criteria.targetFieldId = val.targetFieldId;
    criteria.targetFieldGroup = val.targetFieldGroup;
    criteria.value = val.value;
  }

  public save(): void {
    if (this.form.invalid || this.areCriteriasInvalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }

    this.activeModal.close({
      ...this.form.value,
      criteria: this.criterias,
    });
  }

  public addCriteria(): void {
    this.lastId++;
    this.criterias.push({
      id: this.lastId,
      fieldId: null,
      state: null,
      targetFieldId: null,
      value: null,
    });
    this.calculateModalHeight();
  }

  public removeCriteria(criteria: DecisionCriteria): void {
    this.criterias = this.criterias.filter((crit) => crit.id !== criteria.id);
    this.calculateModalHeight();
  }

  private calculateModalHeight(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
      this.scrollArea?.updateHeight();
    }, 0);
  }

  private loadFields(): void {
    const fields = [
      ...this.workflowsService.logicFields.map((field) => {
        return {
          fieldId: +field.id,
          controlType: field.controlType,
          type: field.type,
          label: field.title,
          fieldGroup: FieldGroupType.Logic,
          options: field.options?.items.map((opt) => {
            return {
              key: opt,
              title: opt,
            };
          }),
        };
      }),
    ];

    if (this.workflowType !== WorkflowType.Company) {
      this.fields = fields;
      return;
    }

    this.workflowsService.getCompanyFields().then((res) => {
      if (res.publicFields && res.publicFields.length > 0) {
        res.publicFields.forEach((field) => {
          fields.push(this.MapField(field, FieldGroupType.Public));
        });
      }

      if (res.privateFields && res.privateFields.length > 0) {
        res.privateFields.forEach((field) => {
          fields.push(this.MapField(field, FieldGroupType.Private));
        });
      }

      if (res.confidentialFields && res.confidentialFields.length > 0) {
        res.confidentialFields.forEach((field) => {
          fields.push(this.MapField(field, FieldGroupType.Confidential));
        });
      }

      if (res.proprietaryFields && res.proprietaryFields.length > 0) {
        res.proprietaryFields.forEach((field) => {
          if (field.status !== 'Inactive') {
            fields.push(this.MapField(field, FieldGroupType.Proprietary));
          }
        });
      }

      this.fields = fields;
    });
  }

  private MapField(field: any, fieldGroup: FieldGroupType): any {
    return {
      fieldId: +field.key,
      controlType: field.controlType,
      type: field.type,
      label: field.title,
      fieldGroup,
      options: field.options,
    };
  }

  private getStates(criteria: DecisionCriteria): DecisionCriteriaState[] {
    const selectedField = this.fields.find((field) => field.fieldId === criteria.fieldId);
    if (!selectedField) {
      return [];
    }

    return this.criteriaStates[selectedField.controlType];
  }
}
